export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';
export const FAVORITE_ROUTE = '/favorites';
export const SEARCH_ROUTE = '/search';
export const RESULT_ROUTE = '/result';
export const DETAILS_ROUTE = '/details/:id';
export const SAVED_SEARCH_ROUTE = '/search/save/list';
export const SAVE_SEARCH_ROUTE = '/search/save';
export const FORGOT_PASSWORD_ROUTE = '/password/forgot';
export const RESET_PASSWORD_ROUTE = '/Account/ResetPassword';
export const CHANGE_PASSWORD_ROUTE = '/password/change';
export const FAQ_ROUTE = '/faq';
export const LEGAL_ROUTE = '/legal';
export const CONTACT_ROUTE = '/contact';
export const GENERAL_CONDITION_ROUTE = '/general-condition';
export const DEMAT_ROUTE = '/demat';
export const ESPACE_FOURNISSEURS_ROUTE = '/espaceFournisseurs';
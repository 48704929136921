<template>
  <div class="footer-container">
    <v-row class="py-5 footer-sub-container"  justify-md-start justify-center>
      <v-col lg="6" md="6" sm="12" class="text-start">
        <v-row class="footer-text-size justify-md-start justify-center">
          Copyright © {{currentYear}} Portailmarchés | Tous Droits Réservés.
        </v-row>
      </v-col>

      <v-col lg="6" md="6" sm="12" class="text-end text-md-end">
        <v-row class="footer-text-size justify-md-end justify-center">
          <router-link  class="footer-link" :to="GENERAL_CONDITION_ROUTE">CGU</router-link >
          <div class="divider">|</div>
          <router-link  class="footer-link" :to="LEGAL_ROUTE">Mentions légales</router-link >
          <div class="divider">|</div>
          <router-link  class="footer-link" :to="FAQ_ROUTE">FAQ</router-link >
          <!-- <div class="divider">|</div>
          <div class="footer-link">Contact</div> -->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {FAQ_ROUTE, GENERAL_CONDITION_ROUTE, LEGAL_ROUTE} from '@/router/routes';

export default {
  name: 'FooterView',
  data() {
    return {
      FAQ_ROUTE,
      LEGAL_ROUTE,
      GENERAL_CONDITION_ROUTE,
      currentYear: new Date().getFullYear(),
    };
  }
};
</script>

<style scoped>
.footer-container {
  background-color: #192937 !important;
  width: 100%;
}

.footer-sub-container {
  color: #F7F9FB;
  font-family: Rubik-Regular, sans-serif;
  width: 100%;
  padding: 0 14.71rem;
}

.footer-text-size {
  font-size: 0.7rem;
  line-height: 1.2rem;
  color: #FFF;
  margin: auto;
}

.footer-link {
  padding: 0 0.5rem;
  text-decoration: none;
  color: white;
  font-weight: 400;
  cursor: pointer;
}

.footer-link:hover {
  text-decoration: underline;
}

.divider {
  padding: 0 0.2rem;
  color: white;
}

/* Mobile-specific styles */
@media (max-width: 600px) {
  .footer-sub-container {
    flex-direction: column !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .footer-text-size {
    font-size: 0.6rem;
    line-height: 1.1rem;
  }

  .footer-link {
    padding: 0 0.3rem;
  }

  .footer-container {
    text-align: left;
  }

  .text-md-end {
    text-align: center !important;
  }

  .text-start {
    text-align: center !important;
  }
}
</style>
